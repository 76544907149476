
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Error from "next/error";

export default function NotFoundPage() {
  return <Error statusCode={404} />;
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  